<template>
  <GridInfoContainer v-if="customFlowConfig.length" title="自定義流程" labelWidth="120" labelGap="12">
    <CustomFlowArea
      :orderId="order.id"
      :customFlowConfig="customFlowConfig"
      :record="orderCustomFlowRecord"
      @refresh="$emit('refresh')"
    />
  </GridInfoContainer>
</template>

<script>
import { computed, defineComponent } from 'vue'
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import CustomFlowArea from '@/components/CustomFlowArea.vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberShopOrderInfoSection',
  components: { GridInfoContainer, CustomFlowArea },
  props: {
    order: { type: Object, default: () => ({}) },
    customFlowConfig: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const orderCustomFlowRecord = computed(() => get(props.order, 'CustomFlowRecords') || [])
    return { orderCustomFlowRecord }
  },
})
</script>

<style scoped lang="postcss">

</style>
