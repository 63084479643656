<template>
  <main v-loading="loading.order" class="member-shop-order-detail">
    <PageTitle
      title="兌換紀錄明細"
      icon="chevron_left"
      btn="紀錄歷程"
      :btnPlain="true"
      @iconClick="$router.push({
        name: 'MemberShopPointExchangeRecord'
      })"
      @btnClick="dialog.history = true"
    />
    <div v-loading="loading.order" class="content-wrapper">
      <MemberShopOrderInfoSection :order="orderData" :actions="availableActions" />
      <MemberShopOrderCustomerInfoSection :order="orderData" :actions="availableActions" />
      <MemberShopOrderPaymentInfoSection :order="orderData" :actions="availableActions" @refresh="refresh" />
      <MemberShopOrderCustomFlowSection :order="orderData" :customFlowConfig="displayCustomFlowConfig" @refresh="refresh" />
      <MemberShopOrderInvoiceInfoSection :order="orderData" :actions="availableActions" @refresh="refresh" />
      <MemberShopOrderProductsTable
        :products="get(orderData, 'MemberStoreOrderProducts')"
        :actions="availableActions"
        :productDetail="orderProuctDetail"
        @refresh="refresh"
      />
      <MemberShopOrderStatisticSection :order="orderData" />
    </div>

    <MemberShopOrderHistoryModal
      v-if="dialog.history"
      :logs="orderLogs"
      :products="get(orderData, 'MemberStoreOrderProducts')"
      @close="dialog.history = false"
    />

    <WarningDialog
      v-if="dialog.cancel"
      title="提示"
      content="是否確認取消訂單？"
      :confirmBtnLoading="loading.cancel"
      @close="dialog.cancel = false"
      @confirm="cancelOrder"
    />
  </main>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import MemberShopOrderInfoSection from './components/MemberShopOrderInfoSection.vue'
import MemberShopOrderCustomerInfoSection from './components/MemberShopOrderCustomerInfoSection.vue'
import MemberShopOrderPaymentInfoSection from './components/MemberShopOrderPaymentInfoSection.vue'
import MemberShopOrderInvoiceInfoSection from './components/MemberShopOrderInvoiceInfoSection.vue'
import MemberShopOrderProductsTable from './components/MemberShopOrderProductsTable.vue'
import MemberShopOrderStatisticSection from './components/MemberShopOrderStatisticSection.vue'
import MemberShopOrderHistoryModal from './components/MemberShopOrderHistoryModal.vue'
import MemberShopOrderCustomFlowSection from './components/MemberShopOrderCustomFlowSection.vue'
import store from '@/store'
import {
  FindMemberShopOrder,
  FindMemberShopOrderLogs,
  FindMemberShopOrderTransitionCodes,
  FindMemberShopOrderProductDetail,
  CancelMemberShopOrder,
} from '@/api/memberShop'
import { useTransitionCode } from './utils'
import { useRoute } from 'vue-router/composables'
import { get } from 'lodash'
import { useCustomFlow } from '@/use/useCustomFlow'

export default defineComponent({
  name: 'MemberShopPointExchangeRecordDetail',
  components: {
    PageTitle,
    MemberShopOrderInfoSection,
    MemberShopOrderCustomerInfoSection,
    MemberShopOrderPaymentInfoSection,
    MemberShopOrderInvoiceInfoSection,
    MemberShopOrderStatisticSection,
    MemberShopOrderProductsTable,
    MemberShopOrderHistoryModal,
    MemberShopOrderCustomFlowSection,
    WarningDialog,
  },
  setup (props) {
    const route = useRoute()
    const { customFlowConfig, displayCustomFlowConfig, getCustomFlowConfig, useCustomFlowFeature } = useCustomFlow('pointExchange')
    const shopId = computed(() => store.getters.shop)
    const orderId = computed(() => route.params.id)
    const loading = reactive({
      order: false,
      cancel: false,
    })
    const orderData = ref(null)
    const orderLogs = ref([])
    const availableActions = ref([])
    const dialog = reactive({
      history: false,
      cancel: false,
    })
    const orderProuctDetail = ref({})

    const checkCode = computed(() => {
      const actions = availableActions.value
      const { actionCode } = useTransitionCode(actions)
      return (code) => actionCode(code)
    })

    const findOrder = async (payload) => {
      const [res, err] = await FindMemberShopOrder(payload)
      if (err) return window.$message.error(err)
      orderData.value = res
    }

    const findOrderLogs = async (payload) => {
      const [res, err] = await FindMemberShopOrderLogs(payload)
      if (err) return window.$message.error(err)
      orderLogs.value = res
    }

    const findOrderTransitionCodes = async (payload) => {
      const [res, err] = await FindMemberShopOrderTransitionCodes(payload)
      if (err) return window.$message.error(err)
      availableActions.value = res.transitionCodes
    }

    const findMemberShopOrderProductDetail = async (payload) => {
      const newPayload = {
        ...payload,
        memberStoreOrderProductId: get(orderData.value, 'MemberStoreOrderProducts[0].id'),
      }
      const [res, err] = await FindMemberShopOrderProductDetail(newPayload)
      if (err) return window.$message.error(err)
      orderProuctDetail.value = res
    }

    const cancelOrder = async () => {
      loading.cancel = true
      const [, err] = await CancelMemberShopOrder({
        shopId: shopId.value,
        id: orderId.value,
      })
      if (err) {
        loading.cancel = false
        window.$message.error(err)
        return
      }
      window.$message.success('已取消訂單！')
      dialog.cancel = false
      refresh()
      loading.cancel = false
    }

    const refresh = async () => {
      const payload = {
        shopId: shopId.value,
        id: orderId.value,
        includeCustomFlowRecord: useCustomFlowFeature.value.record,
      }
      loading.order = true
      await Promise.allSettled([
        findOrder(payload),
        findOrderLogs(payload),
        findOrderTransitionCodes(payload),
        getCustomFlowConfig(),
      ])
      await findMemberShopOrderProductDetail(payload)
      loading.order = false
    }

    onMounted(async () => {
      await refresh()
    })
    return { get, dialog, orderData, checkCode, availableActions, loading, orderLogs, cancelOrder, refresh, orderProuctDetail, displayCustomFlowConfig }
  },
})
</script>

<style scoped lang="postcss">
.content-wrapper {
  @apply flex flex-col gap-[16px];
}
</style>
